export const searchPayloadMaker = (formData) => {
   const { pickUpState, dropState, pickupDate, pickupTime, transferType, dropDate, dropTime, passenger, extraWaitingHour  } = formData;
   const convertedDate = getDateAndTimeFormat(pickupDate,pickupTime);
   let payload = {
    outwardDate: convertedDate,
    origin: `location:${pickUpState?.id}`,
    destination: `location:${dropState?.id}`,
    currency: 'GBP',
    passengers: '2,0,0'
   };
   if(transferType === 'roundTrip') {
      payload.returnDate = getDateAndTimeFormat(dropDate,dropTime);
   } 
   if(passenger){
      payload.passengers = `${passenger}, 0, 0`;
   }
   return payload;
};

export const createBookingPayload = (cab, form) => {
   const quote_id = cab?.id;
   const offer = cab?.metadata?.offer;
   const pickupLocation = form?.pickUpState;
   const dropoffLocation = form?.dropState;
   const transferType = form?.transferType

   return {
    dropoffLocation,
    pickupLocation,
    quote_id,
    offer,
    transferType
   };
}

export const getDateAndTimeFormat = (date, time) => {
    return `${date}T${time}:00`;
}

export const addTimeToDate = (dateTimeStr, minutesToAdd) => {
   const date = new Date(dateTimeStr);
   date.setMinutes(date.getMinutes() + minutesToAdd);
   
   const year = date.getFullYear();
   const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
   const day = date.getDate().toString().padStart(2, '0');
   const hours = date.getHours().toString().padStart(2, '0');
   const minutes = date.getMinutes().toString().padStart(2, '0');
   const seconds = date.getSeconds().toString().padStart(2, '0');
 
   return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
 };

 export const roundingOffAmount = (amount) => {
   return (Math.round(Number(amount * 100))/100).toFixed(2);
 };

 export const getPaymentDetails = (details) => {
   return {
     seats: details?.vehicle?.max_passengers,
     name: details?.vehicle?.title,
     type: details?.vehicle?.type,
     fare: roundingOffAmount(details?.price?.value),
     description: details?.vehicle?.description,
   };
 };

 export const createFetchBookingParams = (data) => {
   const params = {
     startDate: getDateAndTimeFormat(data.startDate || new Date(), '00:00'),
     endDate: getDateAndTimeFormat(data.endDate|| new Date(), '00:00'),
     skip: data.currentPage,
     limit: data.itemsPerPage,
   };
   return params;
 };

 export const dateReadableFormat = (isoDate) => {
   const date = new Date(isoDate);

   const readableDate = `${date.getDate().toString().padStart(2, "0")}/${(
     date.getMonth() + 1
   )
     .toString()
     .padStart(2, "0")}/${date.getFullYear()} ${date
     .getHours()
     .toString()
     .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
     .getSeconds()
     .toString()
     .padStart(2, "0")}`;

   return readableDate;
 };