import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa"; 
import "./contact-us.css"; 
import ContactUsForm from "../../common/contact-us";

const ContactUs = () => {
  return (
    <section className="contact-us-section py-5 bg-light">
      <div className="container">
        {/* Main Title */}
        <h1 className="text-primary text-uppercase text-center mb-3">Contact Us</h1>
        <h2 className="text-secondary text-center mb-4">Contact Info</h2>

        {/* Contact Info and Map */}
        <div className="row">
          {/* Contact Info */}
          <div className="col-md-6 mb-4">
            <div className="card h-100 shadow-sm border-0">
              <div className="card-body">
                <div className="contact-item mb-4">
                  <FaPhoneAlt className="text-primary me-2" size={20} />
                  <span>UK: +44 1274 449382</span>
                </div>
                <div className="contact-item mb-4">
                  <FaPhoneAlt className="text-primary me-2" size={20} />
                  <span>USA: +1 800 5677 800</span>
                </div>
                <div className="contact-item mb-4">
                  <FaPhoneAlt className="text-primary me-2" size={20} />
                  <span>Saudi: +966 541805676 (24 Hours)</span>
                </div>
                <div className="contact-item mb-4">
                  <FaEnvelope className="text-primary me-2" size={20} />
                  <span>info@skyfly.travel</span>
                </div>
                <div className="contact-item">
                  <FaMapMarkerAlt className="text-primary me-2" size={20} />
                  <span>
                    150-168 Manningham Lane, <br />
                    Bradford, BD8 7DT, UK
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Embedded Map */}
          <div className="col-md-6 mb-4">
            <div className="map-container h-100 shadow-sm border-0">
              <iframe
                title="Google Map Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2381.0533486241985!2d-1.759398684462536!3d53.79840737786908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487be54bf7e1a4b3%3A0x7c6d5b51737cf58c!2s150-168%20Manningham%20Ln%2C%20Bradford%20BD8%207DT%2C%20UK!5e0!3m2!1sen!2suk!4v1697891736463!5m2!1sen!2suk"
                width="100%"
                height="100%"
                style={{ border: "0", borderRadius: "8px" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <ContactUsForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
