import React, { useState, useEffect, useContext } from "react";
import "./manage-booking.css";
import UserContext from "../../context/UserContext";
import { useQueryClient } from "react-query";
import { listOfBookings } from "../../api/bookings";
import { createFetchBookingParams, dateReadableFormat } from "../../utils/helper";
import Spinner from "../../common/spinner";

const BookingListing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const itemsPerPage = 20;
  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();

  const fetchAllBooking = (curr) => { 
    setLoading(true);
    queryClient
        .fetchQuery({
          queryKey: ["fetchAllBookings"],
          queryFn: () => {
            const params = createFetchBookingParams({
              currentPage: curr,
              itemsPerPage,
            });
            return listOfBookings(params);
          },
        })
        .then((response) => {
          const allReference = response?.data?.data;
          const pagination = response?.data?.pagination;
          if (allReference) {
            setBookings(allReference);
          }
          if(pagination){
            setPaginationData(pagination);
          }
        })
        .catch((error) => {
          console.error("::::::::Error::::", error);
        })
        .finally(() => {
          setLoading(false);
        });
  }
  useEffect(() => {
    if (user?.loggenInUser) {
      fetchAllBooking(currentPage);
    }
  }, [JSON.stringify(user)]);


  const handlePageChange = (page) => {
    if (page >= 1 && page <= paginationData?.totalPages) {
      setCurrentPage(page);
      fetchAllBooking(page);
    }
  };

  return (
    <div className="booking-listing-container">
      <h2>Your Bookings</h2>
      {loading && <Spinner showOverlay={true} />}
      {bookings.length === 0 ? (
        <p className="no-bookings">No bookings found.</p>
      ) : (
        <>
          <table className="booking-table">
            <thead>
              <tr>
                <th>Booking Reference</th>
                <th>Passengers Name</th>
                <th>Status</th>
                <th>Start Date</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {bookings.map(
                ({ bookingId, lead_passenger, updatedAt, status, createdAt }) => (
                  <tr key={bookingId}>
                    <td>{<b>{bookingId}</b>}</td>
                    <td>{`${lead_passenger?.name} ${lead_passenger?.surname}`}</td>
                    <td>
                      <span className={`status-badge ${status.toLowerCase()}`}>
                        {status}
                      </span>
                    </td>
                    <td>{dateReadableFormat(updatedAt || createdAt)}</td>
                    {/* <td>
                      <button
                        className="view-button"
                        onClick={() =>
                          console.log(`Viewing booking: ${bookingId}`)
                        }
                      >
                        View
                      </button>
                    </td> */}
                  </tr>
                )
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="pagination">
            <button
              className="pagination-button"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            {Array.from({ length: paginationData?.totalPages }, (_, index) => (
              <button
                key={index}
                className={`pagination-button ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className="pagination-button"
              disabled={currentPage === paginationData?.totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default BookingListing;
