const mockPickupLocations = [
  {
    id: 1,
    name: "New York City, NY",
    coordinates: { lat: 40.7128, lng: -74.006 },
  },
  {
    id: 2,
    name: "Los Angeles, CA",
    coordinates: { lat: 34.0522, lng: -118.2437 },
  },
  { id: 3, name: "Chicago, IL", coordinates: { lat: 41.8781, lng: -87.6298 } },
  { id: 4, name: "Houston, TX", coordinates: { lat: 29.7604, lng: -95.3698 } },
  { id: 5, name: "Miami, FL", coordinates: { lat: 25.7617, lng: -80.1918 } },
  { id: 6, name: "Seattle, WA", coordinates: { lat: 47.6062, lng: -122.3321 } },
  {
    id: 7,
    name: "San Francisco, CA",
    coordinates: { lat: 37.7749, lng: -122.4194 },
  },
  {
    id: 8,
    name: "Las Vegas, NV",
    coordinates: { lat: 36.1699, lng: -115.1398 },
  },
  { id: 9, name: "Denver, CO", coordinates: { lat: 39.7392, lng: -104.9903 } },
  { id: 10, name: "Boston, MA", coordinates: { lat: 42.3601, lng: -71.0589 } },
];

const transferType = [
    { label: "Select transfer type", value: ''},
    { label: "One Way", value: 'oneway'},
    { label: "Round Trip", value: 'roundTrip'},
]

const steps = [
    { value: 1, label: "Booking Details" },
    { value: 2, label: "Search Results" },
  ];

const cabDetails = [
    { value: 'name', label: 'Name'},
    { value: 'type', label: 'Type'},
    { value: 'seats', label: 'Seats'},
];

const billingFields = [
    {
      id: "name",
      label: "Full Name",
      type: "text",
      placeholder: "Enter your full name",
      required: true,
    },
    {
      id: "email",
      label: "Email",
      type: "email",
      placeholder: "Enter your email address",
      required: true,
    },
    {
      id: "phone",
      label: "Phone Number",
      type: "tel",
      placeholder: "Enter your phone number",
      required: true,
    },
    {
      id: "address",
      label: "Address",
      type: "text",
      placeholder: "Enter your billing address",
      required: true,
    },
  ];

  const BOOKING_FIELDS = [
    { label: "Pickup Date", key: "pickupDate" },
    { label: "Pickup Time", key: "pickupTime" },
    { label: "Pickup Location", key: "pickupLocation" },
    { label: "Drop Location", key: "dropLocation" },
    { label: "Cab Type", key: "cabType" },
    { label: "Fare", key: "fare" },
  ]
export { mockPickupLocations, transferType, steps, cabDetails, billingFields, BOOKING_FIELDS };