import React, { useState, useEffect } from "react";
import { getMinTime } from "../../constants/formValidation";
import './time.css'

const TimeSelect = ({ date, timeValue, label, id, name, handleChange }) => {

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const time = new Date();
        time.setHours(hour);
        time.setMinutes(minute);
        const formattedTime = time.toTimeString().slice(0, 5);
        options.push({ value: formattedTime, label: formattedTime });
      }
    }
    return options;
  };

  const [timeOptions, setTimeOptions] = useState([]);
  useEffect(() => {
    setTimeOptions(generateTimeOptions());
  }, []);

  return (
    <div className="time-select-container">
    {label && <label className="time-select-label">{label}</label>}
      <select
        id={id}
        name={name}
        value={timeValue}
        disabled={!date}
        onChange={handleChange}
        required
        className="form-input"
      >
        <option value="" disabled>
          Select Time
        </option>
        {timeOptions
          .filter(
            (option) =>
              option.value >= getMinTime(date)
          )
          .map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default TimeSelect;
