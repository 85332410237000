import React from 'react';
import backgroundImage from '../../assets/images/cab_image.png';
import logoImage from '../../assets/images/umrah_taxi_blue.png'; // Import your logo
import './home-page.css'; // Import the updated CSS
import HomeNumbers from './numbers/home-numbers';
import { useNavigate } from 'react-router-dom';

const Home = () => {

    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
        position: 'relative',
    };
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div style={backgroundStyle}>
                <div className="overlay">
                    <div className="content-container">
                        {/* Logo */}
                        <div className="logo-container" onClick={() => navigate('/book-now')}>
                            <img
                                src={logoImage}
                                alt="Umrah Taxi Logo"
                                className="logo-image"
                            />
                        </div>
                        <h1 className="animate-stagger hero-mainheader" onClick={() => navigate('/book-now')}>Book Your Umrah Taxi</h1>
                        <div className="divider"></div>
                        <h2 className="animate-stagger hero-subheader">Your Trusted Partner for Hassle-Free Umrah Transfers</h2>
                        <p className="animate-stagger">
                        At the heart of your <b>Umrah pilgrimage</b>, we offer a <b>professional and reliable online taxi service</b>, ensuring every step of your journey is seamless, comfortable, and on time.
                         With a focus on safety and convenience, our services are tailored to meet the unique needs of international Umrah travelers, making your spiritual journey a truly memorable one.
                        </p>
                        <p className="animate-stagger">
                        With <b>over 26 years of experience</b>, our UK and KSA-based accredited partnerships guarantee excellence in service delivery.
                         Designed specifically for international passengers, our commitment is to provide hassle-free transportation with a deep understanding of the unique requirements of Umrah travelers.
                        </p>
                        <p className="animate-stagger">
                        We also offer flexibility like no other—allowing <b>free amendments or cancellations up to 3 days prior</b> to your booking. This enables you to plan your journey with confidence, knowing that we’re here to adapt to your changing needs.
                         Trust us to make your transfers reliable, comfortable, and stress-free.
                        </p>
                    </div>
                </div>
            </div>
            <HomeNumbers />
        </React.Fragment>
    );
};

export default Home;
