const passengers = {
    label: 'Passengers',
    key: 'passenger',
    defaultVal: 2,
    options: [
        { value: 1, label: '1'},
        { value: 2, label: '2'},
        { value: 3, label: '3'},
        { value: 4, label: '4'},
        { value: 5, label: '5'},
        { value: 6, label: '6'},
        { value: 7, label: '7'},
        { value: 8, label: '8'},
        { value: 9, label: '9'},
        { value: 10, label: '10'}
    ]
};

const luggage = {
    label: 'Luggage',
    key: 'luggage',
    defaultVal: 2,
    options: [
        { value: 1, label: '1'},
        { value: 2, label: '2'},
        { value: 3, label: '3'},
        { value: 4, label: '4'},
        { value: 5, label: '5'},
        { value: 6, label: '6'},
        { value: 7, label: '7'},
        { value: 8, label: '8'},
        { value: 9, label: '9'},
        { value: 10, label: '10'}
    ]
};
export const filtersValues = [
    {...passengers},
    {...luggage},
];

export const listDefaultFilter = {
    luggage: 2,
    passenger: 2
};

 const currentYear = new Date().getFullYear();
 const defaultStartDate = `${currentYear}-01-01`;

 export const defaultFilterDateOptions = {
   startDate: defaultStartDate,
   endDate: new Date().toISOString().split("T")[0],
 };