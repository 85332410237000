import React from "react";
import PropTypes from "prop-types";
import "./travel.css";

const TravelDetails = ({ data }) => {
  const renderOutwardDetails = (outward) => {
    if (outward.flight) {
      return (
        <div className="travel-details-section">
          <h4>Flight Details</h4>
          <p><strong>Airline:</strong> {outward.flight.airline.code}</p>
          <p><strong>Flight Number:</strong> {outward.flight.flight_number}</p>
          <p><strong>Airport:</strong> {outward.flight.name} ({outward.flight.code})</p>
          <p><strong>Flight Time:</strong> {outward.flight.flight_date_time}</p>
          <p><strong>Pickup Time:</strong> {outward.flight.pickup_date_time}</p>
        </div>
      );
    }

    if (outward.accommodation) {
      return (
        <div className="travel-details-section">
          <h4>Accommodation Details</h4>
          <p><strong>Name:</strong> {outward.accommodation.name}</p>
          <p><strong>Address:</strong> {outward.accommodation.address}</p>
        </div>
      );
    }

    return null;
  };

  const renderDestinationDetails = (destination) => {
    if (destination.flight) {
      return (
        <div className="travel-details-section">
          <h4>Flight Details</h4>
          <p><strong>Airline:</strong> {destination.flight.airline.code}</p>
          <p><strong>Flight Number:</strong> {destination.flight.flight_number}</p>
          <p><strong>Airport:</strong> {destination.flight.name} ({destination.flight.code})</p>
          <p><strong>Flight Time:</strong> {destination.flight.flight_date_time}</p>
          <p><strong>Pickup Time:</strong> {destination.flight.pickup_date_time}</p>
        </div>
      );
    }

    if (destination.accommodation) {
      return (
        <div className="travel-details-section">
          <h4>Accommodation Details</h4>
          <p><strong>Name:</strong> {destination.accommodation.name}</p>
          <p><strong>Address:</strong> {destination.accommodation.address}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="travel-details">
      {data.map((item, index) => (
        <div key={index} className="travel-item">
          <h3>{index === 0 ? 'Travel': 'Return'}</h3>
          {renderOutwardDetails(item.outward)}
          {renderDestinationDetails(item.destination)}
          <div className="vehicle-section">
            <h4>Vehicle Details</h4>
            <p><strong>Type:</strong> {item.vehicle.title}</p>
            <p><strong>Description:</strong> {item.vehicle.description}</p>
            <p><strong>Max Passengers:</strong> {item.vehicle.max_passengers}</p>
            <p><strong>Max Luggage:</strong> {item.vehicle.max_luggage}</p>
          </div>
          <div className="route-section">
            <h4>Route Details</h4>
            <p><strong>From:</strong> {item.route.outward.name}</p>
            <p><strong>To:</strong> {item.route.destination.name}</p>
            <p><strong>Distance:</strong> {item.route.kilometers} km</p>
            <p><strong>Duration:</strong> {item.route.minutes} mins</p>
          </div>
          <div className="pickup-instructions">
            <h4>Pickup Instructions</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: item.pickup_instructions,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

TravelDetails.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      outward: PropTypes.object.isRequired,
      destination: PropTypes.object.isRequired,
      vehicle: PropTypes.object.isRequired,
      route: PropTypes.object.isRequired,
      pickup_instructions: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TravelDetails;
