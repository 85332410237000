import React, { useEffect, useState } from "react";
import "./confirmation.css";
import { useQuery } from "react-query";
import { isEmpty } from 'lodash';
import BookingSuccess from "./component/success";
import BookingFailure from "./component/failure";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchBooking } from "../../api/bookings";
import ThreeDotLoader from "../../common/loader";
import Spinner from "../../common/spinner";

const ConfirmationPage = () => {
  const { bookingId } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const [bookingData, setBookingData] = useState({});
  const { isLoading, refetch } = useQuery({
    queryKey: ["fetchBooking"],
    queryFn: () => {
        return fetchBooking(bookingId);
    },
    enabled: false,
    retry: false,
    onSuccess: (response) => {
      // Set the fetched data to local state
      setBookingData(response?.data?.combineBookingData)
    },
  });
  useEffect(() => {
    if(isEmpty(bookingData) && bookingId){
      refetch();
    }
  }, []);

  const renderComponent = () => {
    if(status === 'COMPLETED'){
      return <BookingSuccess bookingData={bookingData}/>
    }
    return <BookingFailure status={status} />
  }

  return (bookingId && (isLoading || isEmpty(bookingData))) ? <Spinner /> : renderComponent();
};

export default ConfirmationPage;

