import React from "react";
import { useNavigate } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa"; // Import an icon from react-icons
import "./failure.css"; // Add custom styles

const BookingFailure = () => {
  const navigate = useNavigate();

  return (
    <div className="failure-container">
      <div className="failure-icon">
        <FaExclamationTriangle />
      </div>
      <h1 className="failure-title">Booking Failed</h1>
      <p className="failure-message">
        We’re sorry, but there was an issue with your booking. Please try again.
      </p>
      <button
        className="failure-button"
        onClick={() => navigate("/book-now")}
      >
        Try Again
      </button>
    </div>
  );
};

export default BookingFailure;
