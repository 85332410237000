import taxi from '../assets/images/taxi.png';
import minivan from "../assets/images/minivan.png";
import minibus from "../assets/images/minibus.png"
import minicoach from "../assets/images/minicoach.png"
export const cabImages = (type) => {
    switch (type.toLowerCase()) {
      case "taxi": {
        return taxi;
      }
      case "minivan": {
        return minivan;
      }
      case "minibus": {
        return minibus;
      }
      case "minicoach": {
        return minicoach;
      }
      default: {
        return taxi;
      }
    }
  };
  