import axiosInstance from "../axiosConfig";
import { roundingOffAmount } from "../helper";


const fetchAllRoutes = async () => {
    return await axiosInstance.get('/api/location/fetch', { withCredentials: true });
}

const fetchAvailableRouteById = async (id) => {
    return await axiosInstance.get('/api/location/fetch/routeById', {
        params: {
            id
        }
    });
}

const fetchAllAvailableRoutes = (parameters) => {
    return axiosInstance.get('api/location/fetch/availibility', {
        params: {
            ...parameters
        }
    });
}

const paymentInfo = (parameters) => {
    const calculatedAmount = roundingOffAmount(parameters?.amount);
    return axiosInstance.post("/api/payment/initiate-payment", {
      countryCode: "826",
      currencyCode: "826",
      amount: calculatedAmount,
      redirectURL: `${process.env.REACT_APP_SERVICE_URL}/api/callback-url`,
      callbackURL: `${process.env.REACT_APP_SERVICE_URL}/api/callback-url`,
    }, { withCredentials: true });
}

export { fetchAllRoutes, fetchAvailableRouteById, fetchAllAvailableRoutes, paymentInfo };