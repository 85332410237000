import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router, Route, Navigate,
  Routes,
} from 'react-router-dom';
import { BookingForm, SignInForm, SignUpPage } from './pages';
import { Footer, Header } from './common';
import Home from './pages/home/index.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ConfirmationPage from './pages/confirmation/index.jsx';
import About from './pages/About/about.jsx';
import ContactUs from './pages/Contact/contact-us.jsx';
import ContactInfo from './common/contact_info/contactInfo.jsx';
import UserContext from './context/UserContext.js';
import UserProfile from './pages/UserProfile/userProfile.jsx';
import BookingListing from './pages/manage-booking/index.jsx';


function App() {
  const { user = {}, logoutUser } = useContext(UserContext);
  useEffect(() => {
    const now = Math.floor(Date.now() / 1000);
    if (user?.loggenInUser && now >= user?.exp) {
      logoutUser();
    }
  }, [JSON.stringify(user)]);
  return (
      <Router>
        <Header />
        <main style={{ minHeight: "calc(100vh - 160px)" }}>
          <Routes>
            {/* Redirect '/' to '/home' */}
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/home" element={<Home />} />
            <Route path="/book-now" element={<BookingForm />} />
            <Route path="/login" element={<SignInForm />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route
              path="/booking-confirmation/:bookingId?"
              element={<ConfirmationPage />}
            />
            {/* <Route
              path="/booking/view/:bookingId?"
              element={<ConfirmationPage />}
            /> */}
            <Route path="/about" element={<About />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/my-bookings" element={<BookingListing />} />
          </Routes>
        </main>
       <ContactInfo/>
        <Footer />
      </Router>
  );
}

export default App;


