import React, { useState } from 'react';
import './book-now.css';
import { steps } from '../../constants/booknow';
import Stepper from './components/stepper';
import SearchCabs from './components/search';
import CabList from './components/listing';


const BookingForm = () => {
  const [formData, setFormData] = useState({
    pickupDate: "",
    pickupTime: "",
    pickupLocation: "",
    dropLocation: "",
    transferType: "",
    dropDate: "",
    dropTime: "",
    extraWaitingHour: 0,
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [cabsList, setCabsListing] = useState({});
  const [onCabSelect, setOnCabSelect] = useState({});
  

  return (
    <>
      <Stepper
        steps={steps}
        currentStep={currentStep}
        onStepChange={setCurrentStep}
      />
      {currentStep === 1 && (
        <SearchCabs
          formData={formData}
          setFormData={setFormData}
          setCabsListing={setCabsListing}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      )}
      {currentStep === 2 && (
        <CabList
          cabs={cabsList}
          formData={formData}
          onSelectCab={setOnCabSelect}
          currentStep={currentStep}
          setFormData={setFormData}
          setCabsListing={setCabsListing}
          setCurrentStep={setCurrentStep}
        />
      )}
    </>
  );
};

export default BookingForm;
