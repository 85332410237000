import React from 'react';
import aboutImage from '../../assets/images/3-logo-png.png'; // Adjust the path accordingly
import './about.css'; // Add custom styles for branding

const About = () => {
    return (
        <section className="about-us-section py-5 bg-light">
            <div className="container">
                {/* Main Title */}
                <h1 className="about-title text-center mb-3">A Few Words About Us</h1>
                <h2 className="about-subtitle text-center mb-4">Striving for Excellence and Rewards</h2>
                <p className="about-text text-justify mb-5">
                As a proud part of <b>SaudiaOnlineTaxi.com</b> and <b>UmrahTaxi.app</b>, we operate in the UK under <b>SkyFly Travel Agent Ltd</b>. Our mission is to simplify the entire Umrah journey for international travelers while earning the rewards of exceptional service.
                With <b>over 26 years of experience</b>, we are fully committed to serving our customers around the clock—<b>24 hours a day, 7 days a week</b>—ensuring your journey is seamless, reliable, and stress-free.
                </p>

                {/* Image Section */}
                <div className="text-center mb-5">
                    <img
                        src={aboutImage}
                        alt="Umrah Taxi Logos"
                        className="img-fluid about-image"
                    />
                </div>

                {/* Features Section */}
                <div className="row">
                    {/* Feature 1 */}
                    <div className="col-md-6 mb-4">
                        <div className="card h-100 shadow-sm border-0">
                            <div className="card-body">
                                <h3 className="feature-title mb-3">Safety First</h3>
                                <p className="feature-text">
                                    We have a fleet of over 450 modern vehicles. We use the latest models of vehicles
                                    for a good and reliable service that are totally safe & comfortable for your
                                    journey. This is to make sure that we never compromise on our customer safety.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Feature 2 */}
                    <div className="col-md-6 mb-4">
                        <div className="card h-100 shadow-sm border-0">
                            <div className="card-body">
                                <h3 className="feature-title mb-3">Expert Drivers</h3>
                                <p className="feature-text">
                                    We only hire expert, professional and vastly experienced drivers for your
                                    convenience, so that your travel journeys will be at ease and in safe hands. All
                                    our drivers are trained to cater to all types of international passengers and
                                    especially for the Umrah Passengers with additional needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
