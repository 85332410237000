import React, { useEffect, useState } from "react";
import "./homeNumbers.css";

const HomeNumbers = () => {
  // State for animated numbers
  const [customers, setCustomers] = useState(0);
  const [vehicles, setVehicles] = useState(0);
  const [years, setYears] = useState(0);
  const [openHours, setOpenHours] = useState(0);

  useEffect(() => {
    const animateNumber = (finalValue, duration, updateState) => {
      let start = 0;
      const increment = finalValue / (duration / 10);

      const timer = setInterval(() => {
        start += increment;
        if (start >= finalValue) {
          start = finalValue;
          clearInterval(timer);
        }
        updateState(Math.floor(start));
      }, 10);
    };

    animateNumber(551420, 2000, setCustomers);
    animateNumber(450, 2000, setVehicles);
    animateNumber(26, 2000, setYears);
    animateNumber(24, 2000, setOpenHours);
  }, []);

  return (
    <section className="home-numbers-section">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-3 mb-4">
            <div className="number-box">
              <h2 className="number">{customers}</h2>
              <p className="label">Happy Customers</p>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="number-box">
              <h2 className="number">{vehicles}</h2>
              <p className="label">Vehicles</p>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="number-box">
              <h2 className="number">{years}</h2>
              <p className="label">Years Experienced</p>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="number-box">
              <h2 className="number">{openHours}</h2>
              <p className="label">Open 24 Hours</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeNumbers;
