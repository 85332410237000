import './link-div.css';
const LinkDiv = ({ children, onClick }) => {
    return (
      <div
        className="clickable-div"
        onClick={onClick}
        role="link"
        tabIndex="0" // Makes it keyboard accessible
      >
        {children}
      </div>
    );
  };

export default LinkDiv;


  