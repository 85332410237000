import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3 className="footer-title">About Us</h3>
          <ul>
            <li>
              <a href="/contact-us">
                <i className="bi bi-envelope-fill"></i> Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3 className="footer-title">Services</h3>
          <ul>
            <li>
              <a href="https://www.skytrip.travel/">
                <i className="bi bi-airplane"></i> Flights
              </a>
            </li>
            <li>
              <a href="https://www.fanadiq.org">
                <i className="bi bi-house-door-fill"></i> Hotels
              </a>
            </li>
            <li>
              <a href="https://www.skyflyholiday.com/">
                <i className="bi bi-building"></i> Umrah Packages
              </a>
            </li>
            <li>
              <a href="https://www.umrahtrain.com">
                <i className="bi bi-train-front-fill"></i> Trains
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3 className="footer-title">Follow Us</h3>
          <ul className="footer-social">
            <li>
              <a href="https://www.facebook.com/skyflyonline">
                <i className="bi bi-facebook"></i> Facebook
              </a>
            </li>
            <li>
              <a href="https://x.com/skyflyonline">
                <i className="bi bi-twitter"></i> Twitter
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/skyflyonline1">
                <i className="bi bi-instagram"></i> Instagram
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@syflyonline">
                <i className="bi bi-youtube"></i> YouTube
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 UmrahTaxi. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
