import React from "react";
import "./spinner.css";

const Spinner = ({ showOverlay = false }) => {
  return (
    <div className={showOverlay ? "spinner-overlay" : ""}>
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
