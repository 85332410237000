import React from 'react';
import './success.css';
// import { TRAVEL_TYPES } from '../../../../constants/success';
import TravelDetails from '../travel';
import { roundingOffAmount } from '../../../../utils/helper';

const Booking = ({ bookingData }) => {
  const {
    prices,
    transfers,
    essential_info,
    priority_numbers,
  } = bookingData;

  return (
    <div className="booking-container">
      {/* Header */}
      <div className="booking-header">
        <h1>Booking Details</h1>
        <p>Your booking has been confirmed successfully!</p>
      </div>

      {/* Prices Section */}
      <section className="booking-section">
        <h2>Prices</h2>
        <div className="card">
          <p>
            <strong>Total:</strong> {prices?.total?.prefix_symbol}
            {prices?.total?.value} {prices?.total?.currency}
          </p>
          <p>
            <strong>Gold Protection:</strong>{' '}
            {prices?.gold_protection?.prefix_symbol}
            {prices?.gold_protection?.value} {prices?.gold_protection?.currency}
          </p>
          {prices.payments.map((payment, index) => (
            <div key={index} className="payment-details">
              <p>
                <strong>Amount:</strong> {payment?.amount?.prefix_symbol}
                {roundingOffAmount(payment?.amount?.value)} {payment?.amount?.currency}
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Transfers Section */}
      <section className="booking-section">
        <h2>Transfers</h2>
        <div className="card">
          <TravelDetails data={transfers} />
        </div>
      </section>

      {/* Essential Information Section */}
      <section className="booking-section">
        <h2>Essential Information</h2>
        <div className="card">
          {Object.entries(essential_info).map(([key, value], index) => (
            <div
              key={index}
              className="essential-info"
              dangerouslySetInnerHTML={{ __html: value }}
            />
          ))}
        </div>
      </section>

      {/* Priority Numbers Section */}
      <section className="booking-section">
        <h2>Priority Numbers</h2>
        <div className="card">
          {priority_numbers.map((number, index) => (
            <p key={index}>
              <strong>{number.country}:</strong> {number.phone}
            </p>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Booking;
