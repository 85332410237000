import React, { useContext, useState } from "react";
import "./drawer.css";
import UserContext from "../../context/UserContext";
import { useNavigate, Link } from "react-router-dom";

const UserProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, logoutUser } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <>
      <div className="header-view-pic-container" onClick={() => setIsOpen(true)}>
        <img
          src={`https://ui-avatars.com/api/?name=${user.name}`}
          alt="User Profile"
          className="header-view-profile-pic"
        />
      </div>

      {/* Drawer */}
      <div className={`drawer ${isOpen ? "open" : ""}`}>
        <div className="drawer-header">
          <h5>User Profile</h5>
          <button className="close-btn" onClick={() => setIsOpen(false)}>
            ×
          </button>
        </div>
        <div className="drawer-body">
          <div className="profile-pic-container">
            <img
              src={`https://ui-avatars.com/api/?name=${user.name}`}
              alt="User Profile"
              className="profile-pic"
            />
          </div>
          <div className="profile-details">
            <p>
              <strong>Name:</strong> {user.name}
            </p>
            <p>
              <strong>Email:</strong> {user.email}
            </p>
            <p>
              <strong>Phone:</strong> {user.phone}
            </p>
          </div>
          <div className="profile-options">
            <ul className="options-list">
              <li className="option-item">
                <Link to="/my-bookings" onClick={() => setIsOpen(false)}>
                  My Bookings
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Logout Button at the Bottom */}
        <button
          className="logout-btn"
          onClick={() => {
            setIsOpen(false);
            logoutUser();
            navigate('/home');
          }}
        >
          Logout
        </button>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div className="drawer-overlay" onClick={() => setIsOpen(false)}></div>
      )}
    </>
  );
};

export default UserProfile;
