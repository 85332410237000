import React, { useContext, useState } from "react";
import "./list.css";
import UserContext from "../../../../context/UserContext";
import PaymentPopupWithRedirect from "../../../../components/payment-popup";
import { useMutation, useQueryClient } from "react-query";
import Modal from "../../../../components/modal";
import SignInForm from "../../../SignIn";
import SignUpPage from "../../../SignUp";
import { fetchAllAvailableRoutes, paymentInfo } from "../../../../utils/locations/api";
import { addTimeToDate, createBookingPayload, getPaymentDetails, roundingOffAmount, searchPayloadMaker } from "../../../../utils/helper";
import { createBooking } from "../../../../api/bookings";
import { cabImages } from "../../../../constants/cab-images";
import Spinner from "../../../../common/spinner";
import FilterComponent from "../../../../components/filter";
import { filtersValues, listDefaultFilter } from "../../../../constants/filter";

const CabList = ({ cabs, onSelectCab, formData, setFormData, setCabsListing }) => {
    const { user = {}} = useContext(UserContext);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [selectedCabs, setSelectedCabs] = useState({});
    const [paymentDetails, setPaymentDetails] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [bookingConfig, setBookingConfig] = useState({});
    const queryClient = useQueryClient();
    const [filters, setFilters] = useState(listDefaultFilter);
    const { mutate: initiatePayment } = useMutation(
      async (parameters) => {
        const data = await paymentInfo(parameters);
        return data;
      },
      {
        onMutate: () => {
          setIsLoading(true);
        },
        onSuccess: (response) => {
          setIsLoading(false);
          setPaymentDetails(response.data.formData);
          setOpenPayment(true);
          return response;
        },
        onError: (error) => {
          setIsLoading(false); // Set loading to false if mutation fails
          setError(
            error?.response?.data.message ||
              "Something went wrong. Please try again."
          );
          console.log("Sign In Error:", error); // Handle error if needed
        },
      }
    );

    const { mutate: bookingApi } = useMutation(
      async (parameter) => {
        const amount = parameter?.amount;
        const signIn = parameter?.signIn || null;
        delete parameter.signIn;
        delete parameter.amount;
        const response = await createBooking({...parameter, totalPassengers: filters.passenger });
        return { data: response.data, amount, fromSignIn: !!signIn };
      },
      {
        onSuccess: (response) => {
          if((response?.fromSignIn || user?.loggenInUser) && response?.amount){
            initiatePayment({ amount: response?.amount });
          }
          return response;
        },
      }
    );

  const applyFilter = (filter) => {
    if(filter?.passenger){
      const updateFormData = JSON.parse(JSON.stringify(formData));
      updateFormData.passenger = filter?.passenger;
      const payload = searchPayloadMaker({ ...updateFormData });
      setIsLoading(true);
      queryClient
        .fetchQuery({
          queryKey: ["allAvailableRoues"],
          queryFn: () => {
            return fetchAllAvailableRoutes(payload);
          },
        })
        .then((response) => {
          const listing = response?.data?.data?.quotes || null;
          const metadata = response?.data?.data?._metadata || null;
          const minutes = response?.data?.data?.route?.minutes || 0;
          const outwardDate =
            response?.data?.data?.route?.outward_pickup_date_time;
          const returnExpectedDate =
            response?.data?.data?.route?.return_pickup_date_time;
          if (listing) {
            let finalState = {
              ...updateFormData,
              pickUpState: {
                ...updateFormData.pickUpState,
                pickupDate: outwardDate,
                pickupDropTime: addTimeToDate(outwardDate, minutes),
              },
              dropState: updateFormData?.dropState,
            };
            if (updateFormData.transferType === "roundTrip") {
              finalState.dropState = {
                ...updateFormData?.dropState,
                returnPickUpDate: returnExpectedDate,
                returnDropTime: payload.returnDate,
              };
            }
            setFormData(finalState);
            if(filter?.luggage){
              console.log(listing);
              const updateListing = listing.filter((ele) => ele?.vehicle?.max_luggage >= Number(filter?.luggage));
              setCabsListing({ listing: updateListing, metadata });
            }
            // setCabsListing({ listing, metadata });
          }
        })
        .catch((error) => {
          console.log(error, "Error");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  const cabDescription = (description = '') => {
      const shortDescription = description.split('.')[0];
      return `${shortDescription}.`;
  }
  return (
    <div className="cabListContainer">
      <FilterComponent
        filters={filters}
        setFilters={setFilters}
        defaultState={listDefaultFilter}
        filtersValues={filtersValues}
        applyFilter={applyFilter}
      />
      <h4>Available Cabs</h4>
      {isLoading && <Spinner showOverlay />}
      {cabs?.listing.length > 0 ? (
        <ul className="cabList">
          {cabs?.listing.map((cab) => (
            <li key={cab.id} className="cabItem">
              <div className="cabImageContainer">
                <img
                  src={cabImages(cab?.vehicle?.type)}
                  alt={`${cab.name} image`}
                  className="cabImage"
                />
              </div>
              <div className="cabDetails">
                <h3 className="cabName">{cab?.vehicle?.title}</h3>
                <h3 className="cabType">{cab?.vehicle?.name}</h3>
                <p className="cabType">Vehicle: {cab?.vehicle?.type}</p>
                <p className="cabFare">{`${
                  cab?.price?.prefix_symbol
                } ${roundingOffAmount(cab?.price?.value)}`}</p>
                <p className="cabSeats">
                  People: {cab?.vehicle?.max_passengers}
                </p>
                <p className="cabSeats">
                  Baggage: {cab?.vehicle?.max_luggage}
                </p>
                <p className="cabDescription">
                  Description: <b>{cabDescription(cab?.vehicle?.description)}</b>
                </p>
              </div>
              <button
                className="selectCabButton smallButton"
                onClick={() => {
                  const getPayloadForBooking = createBookingPayload(
                    { ...cab, metadata: cabs?.metadata },
                    formData
                  );
                  setSelectedCabs(cab);
                  if (user?.loggenInUser) {
                    bookingApi({
                      ...getPayloadForBooking,
                      amount: cab?.price?.value,
                    });
                    onSelectCab(cab);
                  } else {
                    setBookingConfig({
                      ...getPayloadForBooking,
                      amount: cab?.price?.value,
                    });
                    setOpenModal(true);
                  }
                }}
              >
                Select
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No cabs available. Please adjust your search.</p>
      )}
      {openModal && (
        <Modal
          isOpen={openModal}
          onClose={() => {
            bookingApi(bookingConfig);
            setOpenModal(false);
            setBookingConfig({});
          }}
        >
          <SignInForm
            isModal={true}
            setOpenModal={setOpenModal}
            onClickOnSignUp={() => {
              setOpenModal(false);
              setShowSignUp(true);
            }}
            onClose={() => {
              setOpenModal(false);
              bookingApi({ ...bookingConfig, signIn: true });
            }}
          />
        </Modal>
      )}
      {showSignUp && (
        <Modal
          isOpen={showSignUp}
          onClose={() => {
            bookingApi(bookingConfig);
            setShowSignUp(false);
            setBookingConfig({});
          }}
        >
          <SignUpPage
            isModal={true}
            onClose={() => {
              setShowSignUp(false);
              bookingApi({ ...bookingConfig, signIn: true });
            }}
          />
        </Modal>
      )}
      {openPayment && (
        <PaymentPopupWithRedirect
          isOpen={openPayment}
          paymentDetails={paymentDetails}
          onClose={() => setOpenPayment(false)}
          cabDetails={getPaymentDetails(selectedCabs)}
        />
      )}
    </div>
  );
};

export default CabList;


