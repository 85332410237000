import axiosInstance from "../../utils/axiosConfig"

const fetchBooking = (id) => {
    return axiosInstance.get('/api/bookings/fetch',{
        params: {
            id
        }
    }, { withCredentials: true });
};

const createBooking = async (postData) => {
    return axiosInstance.post('/api/bookings/book', postData, {
        withCredentials: true
    });
}

const listOfBookings = async (params) => {
    return axiosInstance.get('/api/bookings/list', {
        params,
        withCredentials: true
    });
}


export { fetchBooking, createBooking, listOfBookings };