import React from "react";
import "./contactInfo.css";

const ContactInfo = () => {
    return (
        <div className="contact-info-section">
            <div className="container">
                <div className="row align-items-stretch">
                    {/* Left Card: Saudi Arabia, USA, UK */}
                    <div className="col-md-6 mb-4 d-flex">
                        <div className="info-box flex-fill">
                            <div className="info-section">
                                <h3 className="info-title">Saudi Arabia</h3>
                                <p className="info-detail">
                                    <strong>WhatsApp:</strong> +966 541805676
                                </p>
                                <p className="info-note">(Open 24 Hours)</p>
                            </div>
                            <div className="info-section">
                                <h3 className="info-title">USA</h3>
                                <p className="info-detail">+1 8172257271</p>
                            </div>
                            <div className="info-section">
                                <h3 className="info-title">UK</h3>
                                <p className="info-detail">+44 1274449382</p>
                            </div>
                        </div>
                    </div>
                    {/* Right Card: SkyFly Travel Info */}
                    <div className="col-md-6 mb-4 d-flex">
                        <div className="info-box flex-fill">
                            <div className="info-section">
                                <h3 className="info-title">SkyFly Travel Agent Ltd</h3>
                                <p className="info-detail">
                                    150-168 Manningham Lane
                                    <br />
                                    Bradford | BD8 7DT | UK
                                </p>
                            </div>
                            <div className="info-section">
                                <p className="info-detail">
                                    <strong>Phone:</strong> 0800 5677 800
                                </p>
                                <p className="info-detail">
                                    <strong>Email:</strong> info@skyfly.travel
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
