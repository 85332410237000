import React, { useState, useContext } from "react";
import "./sign-in.css"; // Assuming the CSS above is saved in this file
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import UserContext from "../../context/UserContext";
import { login } from "../../api/login";
import LinkDiv from "../../components/link-div";
import Spinner from "../../common/spinner";

const SignInForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const { setUser } = useContext(UserContext);
  const history = useNavigate();
  const { mutate } = useMutation(
    async (credentials) => {
        return login(credentials) // Return the data to be used in onSuccess or onError
      },
      {
        onMutate: () => {
          setIsLoading(true); // Set loading to true when mutation starts
        },
        onSuccess: async (response) => {
          setIsLoading(false); // Set loading to false once mutation is successful
          setSuccess(true);
          if (response.data.token) {
            localStorage.setItem('user', JSON.stringify(response.data.token));
            const decodeUser = jwtDecode(response.data.token);
            setUser({
                ...decodeUser,
                loggenInUser: true,
            });
            if(!props?.isModal){
              history('/home');
            } else {
              props?.onClose();
            }
          }// Handle success data if needed
          return response;
        },
        onError: (error) => {
          setIsLoading(false); // Set loading to false if mutation fails
          setError(error?.response?.data.message || 'Something went wrong. Please try again.');
          console.log('Sign In Error:', error); // Handle error if needed
        },
      }
  );
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    if (!email || !password) {
      setError("Both fields are required!");
      return ;
    } 
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(email)) {
      setError('Please enter a valid email.');
      return;
    }
    mutate({ email, password });
  };
  return (
    <div className="sign-up-page">
      {isLoading && <Spinner showOverlay={true}/>}
      <div className="container d-flex justify-content-center align-items-center">
        <div className="form-container custom-sign-in modern-effect">
          <h2 className="text-center mb-4">Sign In</h2>
          {success && (
            <div className="alert alert-success text-center">
              Sign-in successful!
            </div>
          )}
          <form onSubmit={handleSubmit}>
            {/* Email Input */}
            <div className="form-group">
              <input
                type="email"
                id="email"
                className="form-input"
                placeholder=" "
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
            </div>

            {/* Password Input */}
            <div className="form-group">
              <input
                type="password"
                id="password"
                className="form-input"
                placeholder=" "
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label htmlFor="password" className="form-label">
                Password
              </label>
            </div>

            {/* Error Message */}
            {error && <div className="form-error">{error}</div>}

            {/* Submit Button */}
            <button type="submit" className="form-button" disabled={isLoading}>
              {"Sign In"}
            </button>
          </form>
          {props?.isModal && (
            <LinkDiv
              onClick={() => {
                props?.onClickOnSignUp();
              }}
            >
              {" "}
              Sign up
            </LinkDiv>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignInForm;