const getTodayDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const dd = String(today.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
};

const getMinTime = (pickupDate) => {
  const now = new Date();
  const selected = new Date(pickupDate);
  now.setMinutes(now.getMinutes() + 15);
  if (selected.toDateString() === now.toDateString()) {
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  } else {
    return "00:00";
  }
};

export { getTodayDate, getMinTime };
