import React from "react";
import "./filter.css";
import { isEqual } from "lodash";

const FilterComponent = (props) => {
  const { filters, defaultState, setFilters, filtersValues = [], applyFilter = () => {} } = props;

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleReset = () => {
    setFilters(defaultState);
    applyFilter(defaultState);
  };

  const isResetButtonDisabled = () => {
    return isEqual(defaultState, filters);
  };

  return (
    <div className="filter-container shadow-lg rounded-3 p-4 bg-light">
      <div className="filter-group row gy-3 align-items-center">
        {filtersValues.map(({ label, key, options, defaultVal }) => (
          <div className="filter-item col-md-4" key={key}>
            <label htmlFor={key} className="form-label fw-bold text-primary">
              {label}
            </label>
            <div className="input-group">
              <span className="input-group-text bg-primary text-white">
                <i className="bi bi-filter"></i>
              </span>
              <select
                id={key}
                name={key}
                className="form-select"
                value={filters[key] || defaultVal}
                onChange={handleFilterChange}
              >
                <option value="">Select</option>
                {options.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ))}
        <div className="filter-actions col-md-4 d-flex justify-content-end align-items-center">
          <button
            className="btn btn-danger me-2"
            onClick={handleReset}
            disabled={isResetButtonDisabled()}
          >
            <i className="bi bi-arrow-counterclockwise"></i> Reset
          </button>
          <button className="btn btn-success" onClick={() => applyFilter(filters)}>
            <i className="bi bi-check-circle"></i> Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
