import React , { useEffect, useState } from 'react';
import UserContext from './UserContext';
import { jwtDecode } from 'jwt-decode';
import { logout } from '../api/login';
import { useMutation } from 'react-query';

const UserContextProvider = ({ children }) => {
  const defaultAuth = { loggenInUser: false };
  const [user, setUser] = useState(defaultAuth);
  const { mutate: logoutApi } = useMutation(
    async () => {
      const data = await logout();
      return data;
    },
    {
      onSuccess: (response) => {
        setUser(defaultAuth);
        localStorage.removeItem('user');
        return response;
      },
      onError: (error) => {
        console.log("logout", error); // Handle error if needed
      },
    }
  );
  useEffect(() => {
    try {
      if (localStorage.getItem("user")) {
        const userData = JSON.parse(localStorage.getItem("user"));
        const decodeUser = jwtDecode(userData);
        setUser({ ...decodeUser, loggenInUser: true });
      }
    } catch (err) {
      setUser(defaultAuth);
      localStorage.removeItem("user");
    }
  }, []);

  const logoutUser = () => {
    if(localStorage.getItem('user')){
        logoutApi();
    }
  }
  return (
    <UserContext.Provider value={{ user, setUser, logoutUser }}>
        {children}
    </UserContext.Provider>
  )
};

export default UserContextProvider;