import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 10000,
});

// You can also add request and response interceptors here if needed

// Example: Request Interceptor (to add dynamic Authorization header)
axiosInstance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('user'));
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error); // Handle request error
  }
);

// Example: Response Interceptor (handle global errors)
axiosInstance.interceptors.response.use(
  (response) => {
    return response; // Return response if successful
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle Unauthorized errors (e.g., redirect to login)
    }
    return Promise.reject(error); // Propagate error
  }
);

export default axiosInstance;
