import React, { useState, useEffect } from "react";
import {
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineEnvironment,
  AiOutlineCar,
  AiOutlineFieldTime,
} from "react-icons/ai";
import "./search.css";
import { transferType } from "../../../../constants/booknow";
import { useQuery, useQueryClient } from "react-query";
import {
    fetchAllAvailableRoutes,
  fetchAllRoutes,
  fetchAvailableRouteById,
} from "../../../../utils/locations/api";
import { addTimeToDate, searchPayloadMaker } from "../../../../utils/helper";
import { getTodayDate, getMinTime } from "../../../../constants/formValidation";
import Spinner from "../../../../common/spinner";
import TimeSelect from "../../../../common/Time";

const SearchCabs = (props) => {
  const { formData, setFormData, setCabsListing, setCurrentStep, currentStep } =
    props;
  const [selectPickUpLocation, setSelectPickUpLocation] = useState([]);
  const [selectDropLocation, setSelectDropLocation] = useState([]);
  const [error, setError] = useState("");
  const [pickUpState, setPickUpState] = useState({});
  const [dropState, setDropState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadDropList, setLoadDropList] = useState(false);
  const queryClient = useQueryClient();


  const { isLoading: loadPickList, refetch } = useQuery({
    queryKey: ["fetchRoutes"],
    queryFn: fetchAllRoutes,
    enabled: false,
    retry: false,
    onSuccess: (response) => {
      setSelectPickUpLocation(response.data.locations); // Set the fetched data to local state
    },
  });
  
  useEffect(() => {
     if(!selectPickUpLocation.length){
      refetch();
     }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectPickUpLocation)])

  const onSelectPickUpLocation = (e) => {
    const selectedRouteId = e.target.value;
    const selectedRoute = selectPickUpLocation.find(
      (route) => route.id === Number(selectedRouteId)
    );
    setPickUpState(selectedRoute);
    setSelectDropLocation([]);
    if (selectedRoute?.id) {
      setLoadDropList(true);
      queryClient
      .fetchQuery({
        queryKey: ["fetchRoutesById"],
        queryFn: () => {
          return fetchAvailableRouteById(selectedRoute?.id);
        },
      })
      .then((response) => {
        setSelectDropLocation(response?.data?.data?.routes || []);
      })
      .catch((err) => {
        setSelectDropLocation([]);
      })
      .finally(() => {
        setLoadDropList(false);
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const minTime = getMinTime(formData.pickupDate);
    if (name === 'pickupTime' && (formData?.pickupDate === getTodayDate() && value < minTime)) {
      setError('Please select a time later than the current time.');
      return ;
    }
    if(name === 'dropTime' && (formData?.dropDate === getTodayDate() && value <= formData?.pickupTime)){
      setError('Please select a time later than the current time.');
      return ;
    }
    setError('');
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onDropUpLocation = (e) => {
    const selectedRouteId = e.target.value;
    const selectedRoute = selectDropLocation.find(
      (route) => route.id === Number(selectedRouteId)
    );
    setDropState(selectedRoute);
    setError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = searchPayloadMaker({ ...formData, pickUpState, dropState });
    setIsLoading(true);
    queryClient
      .fetchQuery({
        queryKey: ["allAvailableRoues"],
        queryFn: () => {
          return fetchAllAvailableRoutes(payload);
        },
      })
      .then((response) => {
        const listing = response?.data?.data?.quotes || null;
        const metadata = response?.data?.data?._metadata || null;
        const minutes = response?.data?.data?.route?.minutes || 0;
        const outwardDate = response?.data?.data?.route?.outward_pickup_date_time;
        const returnExpectedDate = response?.data?.data?.route?.return_pickup_date_time;
        if (listing) {
          let finalState = {
            ...formData,
            pickUpState: {
              ...pickUpState,
              pickupDate: outwardDate,
              pickupDropTime: addTimeToDate(outwardDate, minutes),
            },
            dropState
          };
          if (formData.transferType === "roundTrip") {
            finalState.dropState = {
              ...dropState,
              returnPickUpDate: returnExpectedDate,
              returnDropTime: payload.returnDate,
            };
          }
          setFormData(finalState);
          setCabsListing({ listing, metadata });
          setCurrentStep(currentStep+1);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err?.response?.data?.message);
      })
      .finally(() => {
        setError('');
        setIsLoading(false);
      });

  };

  return (
    <div className="formContainer">
      {isLoading && <Spinner showOverlay />}
      <form className="form booking-form" onSubmit={handleSubmit}>
        <h2 className="text-center mb-4">Booking Form</h2>

        {/* Pickup Date */}
        <div className="formGroup">
          <label htmlFor="pickupDate" className="form-label">
            Pickup Date
          </label>
          <div className="inputWrapper">
            <AiOutlineCalendar className="icon" />
            <input
              type="date"
              id="pickupDate"
              name="pickupDate"
              className="form-input"
              value={formData.pickupDate}
              onChange={handleChange}
              min={getTodayDate()}
              required
            />
          </div>
        </div>

        {/* Pickup Time */}
        <div className="formGroup">
          <label htmlFor="pickupTime" className="form-label">
            Pickup Time
          </label>
          <div className="inputWrapper">
            <AiOutlineClockCircle className="icon" />
            <TimeSelect
              date={formData?.pickupDate}
              timeValue={formData?.pickupTime}
              id={"pickupTime"}
              name={"pickupTime"}
              handleChange={handleChange}
            />
          </div>
        </div>

        {/* Pickup Location */}
        <div className="formGroup">
          <label htmlFor="pickupLocation" className="form-label">
            Pickup Location
          </label>
          <div className="inputWrapper">
            <AiOutlineEnvironment className="icon" />
            <select
              id="pickupLocation"
              name="pickupLocation"
              className="form-input"
              value={pickUpState?.id || ""}
              onChange={onSelectPickUpLocation}
              required
            >
              <option value="" disabled>
                Select pickup location
              </option>
              {loadPickList ? (
                <option>Loading...</option>
              ) : (
                selectPickUpLocation?.map((ele) => (
                  <option key={ele.id} value={ele.id}>
                    {ele.name}
                  </option>
                ))
              )}
            </select>
          </div>
        </div>

        {/* Drop Location */}
        <div className="formGroup">
          <label htmlFor="dropLocation" className="form-label">
            Drop Location
          </label>
          <div className="inputWrapper">
            <AiOutlineEnvironment className="icon" />
            <select
              id="dropLocation"
              name="dropLocation"
              className="form-input"
              value={dropState?.id || ""}
              disabled={!selectDropLocation.length}
              onChange={onDropUpLocation}
              required
            >
              <option value="" disabled>
                Select drop location
              </option>
              {loadDropList ? (
                <option>Loading...</option>
              ) : (
                selectDropLocation?.map((ele) => (
                  <option key={ele.id} value={ele.id}>
                    {ele.name}
                  </option>
                ))
              )}
            </select>
          </div>
        </div>

        {/* Transfer Type */}
        <div className="formGroup">
          <label htmlFor="transferType" className="form-label">
            Transfer Type
          </label>
          <div className="inputWrapper">
            <AiOutlineCar className="icon" />
            <select
              id="transferType"
              name="transferType"
              className="form-input"
              value={formData.transferType}
              onChange={handleChange}
              required
            >
              {transferType.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>
        {formData.transferType === "roundTrip" && (
          <>
            {/* Drop Date */}
            <div className="formGroup">
              <label htmlFor="dropDate" className="form-label">
                Return Date
              </label>
              <div className="inputWrapper">
                <AiOutlineCalendar className="icon" />
                <input
                  type="date"
                  id="dropDate"
                  name="dropDate"
                  className="form-input"
                  value={formData.dropDate}
                  onChange={handleChange}
                  disabled={!formData.pickupDate}
                  min={formData.pickupDate}
                  required
                />
              </div>
            </div>

            {/* Drop Time */}
            <div className="formGroup">
              <label htmlFor="dropTime" className="form-label">
                Return Time
              </label>
              <div className="inputWrapper">
                <AiOutlineClockCircle className="icon" />
                <TimeSelect
                  date={formData.dropDate}
                  timeValue={formData.dropTime}
                  id={"dropTime"}
                  name={"dropTime"}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </>
        )}

        {/* Extra Waiting Hour */}
        <div className="formGroup">
          <label htmlFor="extraWaitingHour" className="form-label">
            Extra Waiting Hour
          </label>
          <div className="inputWrapper">
            <AiOutlineFieldTime className="icon" />
            <input
              type="number"
              id="extraWaitingHour"
              name="extraWaitingHour"
              className="form-input"
              value={formData.extraWaitingHour}
              onChange={handleChange}
              min="0"
              max="12"
              placeholder="Enter hours"
            />
          </div>
        </div>

        {/* Submit Button */}
        <button type="submit" className="submitButton" disabled={!!error}>
          {"Search"}
        </button>
        {error && <p className="errorMessage">{error}</p>}
      </form>
    </div>
  );
};

export default SearchCabs;