import React from "react";
import "./stepper.css";

const Stepper = ({ steps, currentStep, onStepChange }) => {
  return (
    <div className="stepper-container">
      <div className="d-flex justify-content-between align-items-center position-relative">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            {/* Step Circle */}
            <div
              className={`step-wrapper ${
                currentStep === index + 1 ? "current" : ""
              } ${currentStep > index + 1 ? "completed" : ""}`}
              onClick={() => {
                if (currentStep > index + 1) onStepChange(index + 1);
              }}
            >
              <div className="step-circle">
                {currentStep > index + 1 ? <span>&#10003;</span> : index + 1}
              </div>
              <div className="step-label">{step.label}</div>
            </div>

            {/* Line with Dots */}
            {index < steps.length - 1 && (
              <div className="step-line-container">
                <div
                  className={`step-line ${
                    currentStep > index + 1 ? "completed" : ""
                  }`}
                ></div>
                <div
                  className={`step-dot ${
                    currentStep > index + 1 ? "completed" : ""
                  }`}
                ></div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
