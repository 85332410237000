import React from "react";
import { IoClose } from "react-icons/io5"; // React Icons for the close button
import "./PaymentPopup.css";

const PaymentPopupWithRedirect = ({ 
  isOpen, 
  paymentDetails,
  cabDetails,
  onClose 
}) => {
  const handlePaymentRedirect = () => {
    try {
      const form = document.createElement("form");
      form.method = "post";
      form.action = "https://gateway.cardstream.com/paymentform/";
      for (let key in paymentDetails) {
        if (paymentDetails.hasOwnProperty(key)) {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = paymentDetails[key];
          form.appendChild(input);
        }
      }
      // Add the submit button
      const submitButton = document.createElement("input");
      submitButton.type = "submit";
      submitButton.value = "Pay Now";
      form.appendChild(submitButton);

      // Append the form to the body (invisible)
      document.body.appendChild(form);
      form.submit();
      onClose();
    } catch (error) {
      console.error("Error generating payment form:", error);
    }
  };

  if (!isOpen) return null;

  const { seats, name, type, fare, description } = cabDetails;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <button className="popup-close" onClick={onClose} aria-label="Close">
          <IoClose />
        </button>
        <h2>Confirm Your Payment</h2>
        <div className="payment-details">
          {Object.keys({ seats, name, type, fare, description }).map((key) => (
            <p key={key}>
              <strong>{key.toUpperCase()}:</strong> {cabDetails[key]}
            </p>
          ))}
        </div>
        <button onClick={handlePaymentRedirect} className="confirm-btn">
          Proceed to Payment
        </button>
        <button onClick={onClose} className="close-btn">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PaymentPopupWithRedirect;
