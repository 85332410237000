import axiosInstance from "../../utils/axiosConfig"

const login = (creds) => {
    return axiosInstance.post('/api/auth/login', creds, { withCredentials: true });
};

const register = (registerData) => {
   return axiosInstance.post('/api/auth/register',registerData, { withCredentials: true})
}

const logout = () => {
    return axiosInstance.post('/api/auth/logout',{}, { withCredentials: true});
}
export { login, register, logout };