import React, { useContext } from 'react';
import './header.css';
import {  Link } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import logoImage from '../../assets/images/umrah_taxi_blue.png';
import UserProfile from '../../pages/UserProfile/userProfile';

const Header = () => {
  const { user = {}, logoutUser } = useContext(UserContext);
  return (
    <header className="header">
      <nav
        className="navbar navbar-expand-lg"
        style={{ backgroundColor: "#FFDD87" }}
      >
        <div className="container-fluid">
          {/* Brand Logo and Name */}
          <a href="/" className="navbar-brand d-flex align-items-center">
            <img
              src={logoImage}
              alt="Taxi Cabs Logo"
              style={{
                width: "40px" /* Adjust logo size */,
                height: "40px",
                marginRight: "10px",
              }}
            />
            <span className="fw-bold">Umrah Taxi</span>
          </a>

          {/* Toggler for Responsive Design */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navigation Items */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto align-items-center">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact-us" className="nav-link">
                  Contact Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/book-now"
                  className="nav-link booknow"
                  style={{
                    backgroundColor: "#A91D25", // Branding color
                    color: "white", // White text for contrast
                    borderRadius: "5px",
                    padding: "5px 10px",
                    marginLeft: "10px",
                  }}
                >
                  Booking
                </Link>
              </li>
            </ul>

            {/* Login/Signup */}
            <div className="d-flex align-items-center">
              {user?.loggenInUser ? (
                <div className="nav-item">
                  <UserProfile />
                </div>
              ) : (
                <>
                  <Link to="/login" className="me-4">
                    <button className="btn btn-outline-dark">Login</button>
                  </Link>
                  <Link to="/sign-up">
                    <button className="btn btn-dark">Sign Up</button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;



