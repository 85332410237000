import React, { useState } from "react";
import "./contactus.css";
import { useMutation } from "react-query";
import { postFeedback } from "../../api/feedback";
import Spinner from "../spinner";

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { mutate: feedbackApi } = useMutation(
    async (parameter) => {
        const response = await postFeedback(parameter);
        return response;
    },
    { onMutate: () => {
        setLoading(true);
     },
      onSuccess: (response) => {
        setLoading(false);
        setIsSubmitted(true);
        setFormData({
          customerName: "",
          customerEmail: "",
          message: "",
        });
        setErrors({});
        return response;
      },
      onError: () => {
        setLoading(false);
      }
    }
  );

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Standard email format regex
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Remove errors as user types
    if (name === "customerEmail" && errors.customerEmail) {
      setErrors((prev) => ({ ...prev, customerEmail: "" }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.customerEmail || !validateEmail(formData.customerEmail)) {
      validationErrors.customerEmail = "Please enter a valid email address.";
    }
    if (!formData.customerName) {
      validationErrors.customerName = "Name is required.";
    }
    if (!formData.message) {
      validationErrors.message = "Message is required.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    feedbackApi(formData);
  };

  return (
    <div className="contact-us-container">
    {loading && <Spinner showOverlay={true} />}
      <h2>Contact Our Team</h2>
      {isSubmitted && (
        <p className="success-message">Thank you for reaching out to us!</p>
      )}
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="customerName">Name:</label>
          <input
            type="text"
            id="customerName"
            name="customerName"
            placeholder="Enter your name"
            value={formData.customerName}
            onChange={handleChange}
            required
          />
          {errors.customerName && (
            <p className="error-message">{errors.customerName}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="customerEmail">Email:</label>
          <input
            type="email"
            id="customerEmail"
            name="customerEmail"
            placeholder="Enter your email"
            value={formData.customerEmail}
            onChange={handleChange}
            required
          />
          {errors.customerEmail && (
            <p className="error-message">{errors.customerEmail}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            placeholder="Enter your message"
            rows="5"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          {errors.message && (
            <p className="error-message">{errors.message}</p>
          )}
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactUsForm;

