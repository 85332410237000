import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import './sign-up.css';
import { jwtDecode } from "jwt-decode";
import UserContext from "../../context/UserContext";
import { login, register } from '../../api/login';

const SignUpPage = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { setUser } = useContext(UserContext);
  const { isModal = false, onClose = () => {}} = props;
  const { mutate } = useMutation(
    async (registerData) => {
      const { data } = await register(registerData);
      return data;
    },
    {
      onMutate: () => {
        setIsLoading(true);
      },
      onSuccess: (data) => {
        setIsLoading(false);
        setSuccessMessage('Sign Up Successful!');
        if(isModal){
          login({ email: formData.email, password: formData.password })
            .then((response) => {
              if (response.data.token) {
                localStorage.setItem('user', JSON.stringify(response.data.token));
                const decodeUser = jwtDecode(response.data.token);
                setUser({
                    ...decodeUser,
                    loggenInUser: true,
                });
                onClose();
              }
            })
            .catch((error) => {
              setErrors({ form: error?.message || 'Something went wrong. Please try again.' });
              console.log('Sign In Error:', error);
            });
        }
        console.log('Sign Up Response:', data);
      },
      onError: (error) => {
        setIsLoading(false);
        setErrors({ form: error?.message || 'Something went wrong. Please try again.' });
        console.log('Sign Up Error:', error);
      },
    }
  );
  
  const validate = (field, value) => {
    let error = '';
    switch (field) {
      case 'name':
        if (!value.trim()) {
          error = 'Name is required.';
        } else if (!/^[a-zA-Z\s]+$/.test(value)) {
          error = 'Name should contain only letters and spaces.';
        }
        break;
      case 'email':
        if (!value.trim()) {
          error = 'Email is required.';
        } else if (!/^\S+@\S+\.\S+$/.test(value)) {
          error = 'Enter a valid email address.';
        }
        break;
      case 'phone':
        if (!value.trim()) {
          error = 'Phone number is required.';
        } else if (!/^\d{9,11}$/.test(value)) {
          error = 'Phone number should be between 9-11 digits.';
        }
        break;
      case 'password':
        if (!value.trim()) {
          error = 'Password is required.';
        } else if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value)
        ) {
          error =
            'Password must be at least 8 characters long, include one uppercase letter, one lowercase letter and one number';
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validate(name, value);
  };

  const checkFormValidity = () => {
    const isValid =
      !errors.name &&
      !errors.email &&
      !errors.phone &&
      !errors.password &&
      !!formData.name &&
      !!formData.email &&
      !!formData.phone &&
      !!formData.password;
    return isValid
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    setSuccessMessage('');

    // Validate all fields before submission
    Object.keys(formData).forEach((field) => validate(field, formData[field]));

    if (Object.values(errors).some((error) => error)) {
      return;
    }
    mutate(formData);
  };

  return (
    <div className="sign-up-page">
      <div className="form-container custom-sign-in">
        <h2 className="text-center mb-4">Sign Up</h2>
        <form onSubmit={handleSubmit}>
          {/* Name Input */}
          <div className="form-group">
            <input
              type="text"
              id="name"
              name="name"
              className="form-input"
              placeholder=" "
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label htmlFor="name" className="form-label">
              Full Name
            </label>
            {errors.name && <div className="form-error">{errors.name}</div>}
          </div>

          {/* Email Input */}
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              className="form-input"
              placeholder=" "
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label htmlFor="email" className="form-label">
              Email Address
            </label>
            {errors.email && <div className="form-error">{errors.email}</div>}
          </div>

          {/* Phone Input */}
          <div className="form-group">
            <input
              type="tel"
              id="phone"
              name="phone"
              className="form-input"
              placeholder=" "
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <label htmlFor="phone" className="form-label">
              Phone Number
            </label>
            {errors.phone && <div className="form-error">{errors.phone}</div>}
          </div>

          {/* Password Input */}
          <div className="form-group">
            <input
              type="password"
              id="password"
              name="password"
              className="form-input"
              placeholder=" "
              value={formData.password}
              onChange={handleChange}
              required
            />
            <label htmlFor="password" className="form-label">
              Password
            </label>
            {errors.password && <div className="form-error">{errors.password}</div>}
          </div>

          {/* Form Error */}
          {errors.form && <div className="form-error">{errors.form}</div>}

          {/* Submit Button */}
          <button type="submit" className="form-button" disabled={isLoading || !checkFormValidity()}>
            {isLoading ? 'Signing Up...' : 'Sign Up'}
          </button>
        </form>

        {/* Loading Spinner */}
        {isLoading && (
          <div className="form-loading">
            <div className="spinner"></div>
            <span>Signing up...</span>
          </div>
        )}

        {/* Success Message */}
        {successMessage && <div className="success-message">{successMessage}</div>}
      </div>
    </div>
  );
};

export default SignUpPage;
